import { useState, useContext } from "react"
import { GlobalContext } from './_app'


import { motion, useScroll, useTransform } from 'framer-motion'

import Image from "next/image"
import Link from 'next/link'

import { Header, HeaderShim } from "../fuselage/global/header/header"
import { Footer } from '../fuselage/theme/2024/layout/footer/footer'

import { Reveal } from "../fuselage/animation/reveal"
import { Pop, Slide, Fade } from "../fuselage/animation/animation"

import { section } from "framer-motion/client"
import { ArrowButton } from "../fuselage/components/arrow-button/arrow-button"

import CSS from '../css/app/tourism-and-aviation-connectivity-forum.module.css'

export default function Page () {

    const globals = useContext(GlobalContext)
    // console.log('globals:', globals)


    let  scroll  = useScroll()
    let y = useTransform( scroll.scrollY, [0, 1000], ["0%", "30%"])
    
    return (
        <>

            <Header mainMenu={globals.nodes} />

            <HeaderShim />

            <div className={CSS.background}>

                <motion.div className={CSS.bgSquiggle} style={{ y }}>
                    <img className={CSS.logo} src="/assets/workshops/tacf/bg-squiggle.png" alt="" />
                </motion.div>

                
                <header className={CSS.header}>
                    <div className={CSS.brandBlock}>
                        <img className={CSS.logo} src="/assets/workshops/tacf/tacf-logo.png" alt="" />
                        <div>
                            <p className='fs-3 fw-600 lh-1'>Tourism & Aviation Connectivity&nbsp;Forum</p>
                            <p className='fw-600 mt-xxs'>Wednesday 11 June 2025</p>
                            <p>Golden Tulip Zanzibar Airport</p>
                        </div>
                    </div>
                    <div className={CSS.actions}>
                        <Link href={'/'}>
                            <img src="/assets/workshops/tacf/aviadev-logo-inverse.png" alt="" />
                        </Link>
                    </div>
                </header>


                <section className={CSS.eventTheme}>
                    <p className={CSS.eventThemeTitle}>Uniting stakeholders. Closing the loop</p>
                </section>

                <section className={CSS.box01}>
                    <p className='fs-5 fw-500'>Tourism engagement is often the missing link in the route development process. Destinations that fail to fully integrate tourism with their aviation strategies risk losing airline routes — and once those routes are gone, it is difficult to bring them back.</p>
                </section>

                <section className={CSS.bannerImage}>
                    <img src="/assets/workshops/tacf/banner-image-01.jpg" alt="" />
                </section>

                <section className={CSS.columns}>
                    <div>
                        <section className={CSS.box02}>
                            <p className='fs-6'>This forum will provide practical, hands-on approaches for improving route development strategies across Sub-Saharan Africa by engaging key tourism stakeholders. Featuring insights from Tourism CEOs, airports, and airlines, this forum will explore how collaboration can unlock success in the aviation industry. The event will address the challenges of route development in Africa and highlight how tourism engagement can make all the difference in ensuring routes thrive.</p>
                        </section>

                        <section className={CSS.callout}>
                            <p className='fs-2'>Registration is included in your AviaDev ticket <Link href="/">www.aviadev.com</Link></p>
                        </section>
                    </div>

                    <div>
                        <section className={`${CSS.box03} formatted`}>
                            <p className='fs-2 mb-sm tacf-green'>WHO SHOULD ATTEND?</p>
                            <ul>
                                <li><strong>Tourism CEOs and Executives:</strong> Gain insights into how tourism can actively drive and sustain successful route development.</li>
                                <li><strong>Airline and Airport Representatives:</strong> Learn how collaborating with tourism stakeholders can enhance route success and avoid costly route failures.</li>
                                <li><strong>Business Developers and Route Planners:</strong> Discover best practices for engaging with local tourism sectors to maximize the potential of new routes.</li>
                                <li><strong>Destination Marketing Organizations (DMOs):</strong> Understand the critical role of tourism engagement in supporting long-term aviation partnerships.</li>
                                <li><strong>Government Officials and Policy Makers:</strong> Explore strategies for fostering a collaborative environment between tourism and aviation to boost connectivity in the region.</li>
                            </ul>

                            <p className='fw-400 mt-sm caps tacf-green'>In partnership with:</p>
                            <div className={CSS.partnerGrid}>
                                <img className={CSS.partnerLogo} src="/assets/workshops/tacf/helm.png" alt="" />
                                <img className={CSS.partnerLogo} src="/assets/workshops/tacf/SADC-logo.png" alt="" />
                                <img className={CSS.partnerLogo} src="/assets/workshops/tacf/VA-logo.png" alt="" />
                            </div>

                            <motion.div className={CSS.sideSquiggle}  style={{ y }}>
                                <img src="/assets/workshops/tacf/side-squiggle.png" alt="" />
                            </motion.div>
                        </section>
                    </div>

                </section>


            </div>

            <Footer 
				socialConnections={globals.globalSets[2].socialConnections} 
				contactInfo={globals.globalSets[2].contactDetails[0].body}
				hubspotForm={globals.globalSets[2].hubspotForm[0] ? globals.globalSets[2].hubspotForm[0] : null}
			/>
        </>
    )
}



export const TacfBanner = () => {


    return (
        <section className={CSS.banner}>
            <div className={CSS.bannerSquiggleContainer}>
                <img className={CSS.bannerSquiggle} src="/assets/workshops/tacf/bg-squiggle.png" alt="" />
            </div>

            <div className={CSS.bannerBrandBlock}>
                <Pop><img className={CSS.logo} src="/assets/workshops/tacf/tacf-logo.png" alt="" /></Pop>
                <Slide>
                    <div>
                        <p className='fs-3 fw-600 lh-1'>Tourism & Aviation Connectivity&nbsp;Forum</p>
                        <p className='fw-600 mt-xxs'>Wednesday 11 June 2025</p>
                        <p>Golden Tulip Zanzibar Airport</p>
                    </div>
                </Slide>
            </div>
            <div className={CSS.bannerContent}>
                <Fade>
                    <p className='fs-5 fw-500'>Tourism engagement is often the missing link in the route development process. Destinations that fail to fully integrate tourism with their aviation strategies risk losing airline routes — and once those routes are gone, it is difficult to bring them back.</p>
                </Fade>
                <div className="mt-sm">
                    <Pop inline>
                        <ArrowButton href={`/tourism-and-aviation-connectivity-forum`} solid theme={{ arrow: `white`, border: `var(--accent)`, label: `white`}} >
                        Find Out More</ArrowButton>
                    </Pop>
                </div>
            </div>
        </section>
    )
}

